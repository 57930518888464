<template>
  <div>
    <v-card class="mx-4 mb-4" elevation="0">
      <v-card-title class="py-1 mb-2">
        <!-- <v-icon color="white" class="mr-2 ml-0 pl-0">mdi-file-table-box-outline</v-icon> -->
        Upload Samples
        <span v-if="errorMessage" class="red--text body-2 ml-3">{{ errorMessage }}</span>
      </v-card-title>
      <v-card-text class="d-flex flex-row">
        <VueFileAgent
          class="flex-grow-1"
          ref="vueFileAgent"
          :theme="'list'"
          :multiple="false"
          :deletable="true"
          :meta="true"
          :accept="'.zip'"
          :maxSize="'500MB'"
          :maxFiles="1"
          :helpText="'Click here to choose your SAMPLEs file or drag it here (*.zip).'"
          :errorText="{
            type: 'Invalid file type. Only zip Allowed',
            size: 'Files should not exceed 500MB in size',
          }"
          @select="filesSelected($event)"
          @beforedelete="onBeforeDelete($event)"
          @delete="fileDeleted($event)"
          @upload="onUploaded($event)"
          @upload:error="onUploadError($event)"
          v-model="fileRecords"
        ></VueFileAgent>

        <v-btn icon large class="mt-2 ml-2" :disabled="!fileRecordsForUpload.length" color="primary " @click="uploadFiles">
          <v-icon large>mdi-upload-outline</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card class="mx-4 mb-4" elevation="0">
      <v-card-title class="py-1 mb-2">
        <!-- <v-icon color="white" class="mr-2 ml-0 pl-0">mdi-file-table-box-outline</v-icon> -->
        <span v-if="samples.length > 0" class="mr-1"> {{ sampleStats.total }}</span> Samples
        <span v-if="samples.length > 0" class="body-2 ml-2">( Affected: {{ sampleStats.affected }}, Unaffected: {{ sampleStats.unaffected }}, Unknown: {{ sampleStats.unknown }})</span>
      </v-card-title>
      <v-card-text class="d-flex flex-row">
        <v-data-table ref="detailGrid" :loading="loading" loading-text="Loading the samples ..." :headers="headers" :items="samples" class="elevation-1 mb-8" :item-key="'id'" multi-sort>
          <template v-slot:no-data> No Sample </template>
          <template v-slot:item.actions="{ item }">
            <v-icon color="primary" @click="showPedigree(item)"> mdi-family-tree </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="pedigreeDialog" max-width="500px">
          <v-card class="grey lighten-2" style="position: absolute; top: 50px; width: 500px;"          >
            <v-card-title class="d-flex justify-space-between text-h5 grey lighten-2"  > 
              <div class="d-flex flex-row">
                {{selectedSample?.sample_name}}
              </div>
              <v-btn icon small @click="pedigreeDialog=false" ><v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-card-text class="white">
              <PatientPedigree v-if="selectedSample" :sampleInfo="selectedSample" :cohortId="1111" :projectId="projectId"></PatientPedigree>
  
            </v-card-text>
          </v-card>
    </v-dialog>

  </div>
</template>
<script>
import PatientPedigree from "@/components/LucidAllAnalysis/PatientPedigree.vue";

import VueFileAgentPlugin from "vue-file-agent";
import ApiIntegrface from "@/api/api.js";
// import store from "@/store";
// eslint-disable-next-line no-unused-vars
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
const VueFileAgent = VueFileAgentPlugin.VueFileAgent;
import config from "@/config.js";
import store from "@/store";
import { getSamples } from "@/api/sample.js";

export default {
  name: "ProjectSamples",
  components: { VueFileAgent, PatientPedigree },
  data: () => ({
    fileRecords: [],
    errorMessage: null,
    annotations: [],
    // uploadHeaders: { "Content-Type": "/form-data", Authorization: "Bearer " + store.state.Token },
    uploadHeaders: { "Content-Type": "application/zip", Authorization: "Bearer " + store.state.Token },
    fileRecordsForUpload: [], // maintain an upload queue
    projectId: null,
    selectedSample: null,
    pedigreeDialog: null,
    samples: [],
    loading: false,
    headers: [
      { text: "Id", value: "id", class: "primary white--text ", cellClass: "variantCell", sortable: false, align: "center" },
      { text: "Name", value: "sample_name", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Family_id", value: "family_id", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Sex", value: "sex", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Disease Status", value: "disease_status.name", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Father", value: "father", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Father Status", value: "father_status.name", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Mother Status", value: "mother_status.name", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Mother", value: "mother", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Age", value: "age", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Relatives", value: "relatives", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Twins", value: "twins", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Disease Inheritance", value: "disease_inheritance", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: "Hpo Terms", value: "hpo_terms", class: "primary white--text ", cellClass: "variantCell", sortable: true, align: "center" },
      { text: 'Pedigree', value: 'actions', class: "primary white--text ",  cellClass: "variantCell", sortable: false , align: "center"},

    ],
  }),
  created() {
    this.projectId = Number(this.$route.params.id);
    this.loadSamples();
  },
  computed: {
    uploadUrl() {
      return `${config.backendEndpoint}/api/v1/pedigree/${this.projectId}/upload/`;
    },
    sampleStats() {
      let x = {};
      x.affected = this.samples.reduce((sum, item) => sum + (item.disease_status.name == "affected" ? 1 : 0), 0);
      x.unaffected = this.samples.reduce((sum, item) => sum + (item.disease_status.name == "unaffected" ? 1 : 0), 0);
      x.unknown = this.samples.reduce((sum, item) => sum + (item.disease_status.name == "unknown" ? 1 : 0), 0);
      x.total = this.samples.length;
      return x;
    },
  },
  methods: {
    showPedigree(sample){
      this.selectedSample = null
      this.pedigreeDialog = false
        
      setTimeout(()=>{
        this.selectedSample = sample
        this.pedigreeDialog = true
      }, 100)
      // console.log(sample)
    },
    loadSamples() {
      this.loading = true;
      const _this = this;
      getSamples(0, this.projectId, (res) => {
        this.samples = res;
        _this.loading = false;
      });
    },
    onUploadError(err) {
      const rsp = err[0]?.response || null;
      if (rsp.status == 400) {
        this.errorMessage = " Invalid File uploaded! " + JSON.stringify(rsp.data);
        // console.log(rsp.data?.file)
      }
    },
    onUploaded(responses) {
      var succeeded = true;
      for (var rsp of responses) {
        if (rsp.error) {
          console.log("onUploaded", responses);
          succeeded = false;
          break;
        }
      }
      if (succeeded) {
        this.errorMessage = null;
        this.$root.notify.show({ message: "SAMPLEs file imported successfully " });
        this.$refs.vueFileAgent.deleteFileRecord(this.fileRecords[0]);
        this.fileRecords = [];
        this.loadSamples();
      }
    },
    uploadFiles: function () {
      const _this = this;
      const api = new ApiIntegrface();
      const uploadUrl = this.uploadUrl + _this.fileRecordsForUpload[0].file.name;
      console.log(_this.fileRecordsForUpload);
      if (api.isRefreshRequired()) {
        api.doRefreshToken().then(() => {
          _this.$refs.vueFileAgent.upload(uploadUrl, _this.uploadHeaders, _this.fileRecordsForUpload);
        });
      } else {
        _this.$refs.vueFileAgent.upload(uploadUrl, _this.uploadHeaders, _this.fileRecordsForUpload);
      }
    },
    deleteUploadedFile: function (fileRecord) {
      this.errorMessage = null;
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      this.errorMessage = null;
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm("Are you sure you want to delete?")) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      this.errorMessage = null;
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>